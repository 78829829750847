<div class="devices-section">
  <h3 class="devices-section__title">My Devices</h3>

  <div class="devices-section__wrapper">
    <div *ngFor="let device of  devices$  | async; index as i;"
         class="devices-section__item">
      <div  class="devices-section__item_wrapper">

        <div>


          <div class="devices-section__item_status"
               [ngClass]="{ 'activated' : device.device.monetizationStatus === MONETIZATION_STATUSES.ACTIVE }">
          <span
            class="icon-crystal"></span> {{ device.device.monetizationStatus === MONETIZATION_STATUSES.ACTIVE ? 'Activated' : 'Not active' }}
          </div>
          <div class="devices-section__item_hostname">PC #{{ i + 1 }} | {{ device.systemInfo?.os?.hostname }}</div>

          <div *ngFor="let gpu of device.systemInfo?.gpu" class="devices-section__item_gpus">
            {{ gpu?.model }} {{ gpu.vram | size }}
          </div>

          <div class="devices-section__item_cpu">
            {{ device.systemInfo?.cpu?.manufacturer }} {{ device.systemInfo?.cpu?.brand }}
          </div>

          <div class="devices-section__item_action">
            <app-button *ngIf="device.device.monetizationStatus === MONETIZATION_STATUSES.ACTIVE; else deactivate"
                        [attr.disabled]="isToggleMonetizationLoading"
                        [buttonData]="deactivateBtn"
                        (click)="toggleMonetization(device.device.id, MONETIZATION_STATUSES.INACTIVE)" />
            <ng-template #deactivate>
              <app-button [buttonData]="activateBtn"
                          [attr.disabled]="isToggleMonetizationLoading"
                          (click)="toggleMonetization(device.device.id, MONETIZATION_STATUSES.ACTIVE)" />
            </ng-template>
          </div>

        </div>

        <div>
          <hr>

          <div class="devices-section__item_activity">

            <div *ngIf="device.lastActive" class="devices-section__item_activity--data">
              {{ device.lastActive | date:'dd.MM.y, h:mm' }}
              <p>Last activity</p>
            </div>

            <div class="devices-section__item_activity--data">
              {{ device.totalRewards | number:'1.5-5':'en-US' }}
              <p>Rewards</p>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>

  <div class="devices-section__wrapper-skeleton" *ngIf="loading;">
    <div class="devices-section__item skeleton" *ngFor="let n of skeletonArray">
      <div class="devices-section__item_status skeleton-box"></div>
      <div class="devices-section__item_hostname skeleton-box"></div>
      <div class="devices-section__item_gpus skeleton-box"></div>
      <div class="devices-section__item_cpu skeleton-box"></div>
      <div class="devices-section__item_action skeleton-box"></div>
      <div class="devices-section__item_last-activity skeleton-box"></div>
    </div>
  </div>
</div>
