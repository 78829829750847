import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgClass, NgComponentOutlet, NgForOf, NgIf, SlicePipe } from '@angular/common';

interface MenuItem {
  name: string;
  action: () => void;
  icon?: string;
}

@Component({
  selector: 'app-dropdown-menu',
  standalone: true,
  templateUrl: './dropdown-menu.component.html',
  imports: [NgClass, SlicePipe, NgIf, NgForOf, NgComponentOutlet],
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent {
  @Input() items: MenuItem[] = [];
  @Input() align: 'left' | 'center' | 'right' = 'center';
  @Input() theme: 'light' | 'dark' = 'dark';
  isOpen = false;

  constructor(private el: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  togglePopup() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.cdr.detectChanges();
      this.checkPosition();
    }
  }

  checkPosition() {
    const popup = this.el.nativeElement.querySelector('.dropdown-menu__popup');

    if (!popup) return;

    const rect = popup.getBoundingClientRect();
    const outOfBoundsRight = rect.right > document.body.clientWidth;
    const outOfBoundsBottom = rect.bottom > window.innerHeight;

    console.log(rect, window.innerWidth, document.body.clientWidth);

    if (outOfBoundsRight) {
      this.renderer.setStyle(popup, 'right', '0px');
      this.renderer.setStyle(popup, 'left', 'auto');
      this.renderer.setStyle(popup, 'transform', 'none');
    }

    if (outOfBoundsBottom) {
      this.renderer.setStyle(popup, 'bottom', '0px');
      this.renderer.setStyle(popup, 'top', 'auto');
    }
  }

  onMenuItemClick(action: () => void) {
    action();
    this.isOpen = false; // Close the popup after an action is performed
  }
}
