<table aria-describedby="transactions">
  <thead>
  <tr>
    <th>Date and time</th>
    <th>Category</th>
    <th>Amount</th>
    <th>Address</th>
    <th>TxID</th>
    <th>Status</th>
  </tr>
  </thead>
  <tbody *ngIf="withdrawals.length > 0; else emptyTable">
  <tr
    *ngFor="
        let withdrawal of withdrawals
          | paginate
            : {
                itemsPerPage: this.withdrawalService.itemsPerPage,
                currentPage: this.withdrawalService.currentPage,
                totalItems: this.withdrawalService.totalItems
              }
      ">
    <td class="date">{{ withdrawal.date + 'Z' | date : 'y-MM-dd, hh:mm' }}</td>

    <td>{{ withdrawal.category[0].toUpperCase() + withdrawal.category.toLowerCase().slice(1) }}</td>

    <td>
      {{ roundUpToX(withdrawal.amount, 4) }}
      <span class="currency">{{ withdrawal.currency === 'USDC_POLYGON' ? 'USDC' : withdrawal.currency }}</span>
    </td>

    <td>
      <div *ngIf="withdrawal.walletAddress" class="wallet-address">
        <span>{{ withdrawal.walletAddress | normalizeWallet }}</span>
        <span class="icon icon-Copy-2 wallet-address__copy" (click)="onCopy(withdrawal.walletAddress)"></span>
      </div>
    </td>

    <td>
      <div>
        <div *ngIf="withdrawal.transferTxHash" class="wallet-address">
          <span (click)="openBscScan(withdrawal.transferTxHash)" class="tx-hash">{{ withdrawal.transferTxHash | normalizeWallet }}</span>
          <span class="icon icon-Copy-2 wallet-address__copy" (click)="onCopy(withdrawal.transferTxHash)"></span>
        </div>
      </div>
    </td>

    <td class="status">
      <div
        class="status-background"
        [ngStyle]="{
            'color': getStatusColor(withdrawal.status)
          }"
      >{{ normalizeStatus(withdrawal.status) }}
      </div
      >
    </td>
  </tr>
  </tbody>
  <ng-template #emptyTable>
    <tbody>
    <tr>
      <td colspan="6" class="empty-row">None</td>
    </tr>
    </tbody>
  </ng-template>
</table>
