import { Component, OnInit, ViewChild } from '@angular/core';
import { formatDate, NgForOf, NgIf } from '@angular/common';
import { ApexOptions, ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { TooltipModule } from '@amin-karimi/ng2-tooltip-directive';
import { FILTER_DATE_PERIOD } from '../../enums/date.enum';
import { getFilterDatePeriod } from '../../utils/date.utils';

@Component({
  selector: 'app-metrics-monetization',
  templateUrl: './metrics-monetization.component.html',
  standalone: true,
  imports: [NgForOf, NgApexchartsModule, NgIf, TooltipModule],
  styleUrl: './metrics-monetization.component.scss'
})
export class MetricsMonetizationComponent implements OnInit {
  protected MONETIZATION_FILTERS = FILTER_DATE_PERIOD;

  @AutoUnsubscribe()
  private monetizationSub: Subscription | undefined;

  @ViewChild('chart') chart!: ChartComponent;

  chartOptions: ApexOptions = {
    chart: {
      height: 260,
      width: '100%',
      type: 'area',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    series: [
      {
        name: 'GMRX',
        data: []
      }
    ],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 1.89,
      colors: ['#3A62FF']
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    xaxis: {
      labels: {
        style: {
          colors: '#8C919A',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        },
        rotateAlways: true,
        rotate: 0,
        offsetY: 20
      },
      tooltip: {
        enabled: false
      },
      tickAmount: 12
    },
    yaxis: {
      logarithmic: false,
      labels: {
        formatter(val) {
          return String(Math.trunc((val / 100000) * 100) / 100);
        },
        style: {
          colors: '#8C919A',
          fontSize: '14px',
          fontFamily: 'Open Sans'
        }
      }
    },
    tooltip: {
      marker: {
        show: false
      },
      theme: 'light'
    },
    markers: {
      colors: ['#fff'],
      strokeColors: '#FFA000',
      strokeWidth: 2
    }
  };

  selectedFilter: FILTER_DATE_PERIOD = FILTER_DATE_PERIOD.W1;

  isLoading = true;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.setFilter(FILTER_DATE_PERIOD.W1);
  }

  setFilter(selectedFilter: FILTER_DATE_PERIOD) {
    this.selectedFilter = selectedFilter;
    this.loadChartData(selectedFilter);
  }

  loadChartData(filter: FILTER_DATE_PERIOD) {
    const dateFilter = getFilterDatePeriod(filter);

    this.monetizationSub?.unsubscribe();
    this.monetizationSub = this.dashboardService.getMonetizationMetricsRequest(dateFilter).subscribe((response) => {
      const series = response.data?.rewards.map((reward) => reward.value * 100000);
      const value = response.data?.rewards.map((reward) => reward.date);
      this.createChart(series || [], value || []);
      this.isLoading = false;
    });
  }

  private createChart(series: number[], categories: string[]) {
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: 'GMRX',
          data: series
        }
      ],
      xaxis: {
        ...this.chartOptions.xaxis,
        categories: categories,
        labels: {
          ...this.chartOptions.xaxis?.labels,
          formatter(value) {
            return value ? formatDate(value, 'dd.MM', 'en-US') : '';
          }
        }
      }
    };
  }
}
