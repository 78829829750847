import { Component, OnInit } from '@angular/core';
import { CustomCheckboxDropdownComponent } from '../custom-checkbox-dropdown/custom-checkbox-dropdown.component';
import { NgForOf, NgIf } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { TransactionsTableComponent } from '../transactions-table/transactions-table.component';
import { sortByCreatedOn } from '../../utils/shared.utils';
import { debounceTime, skip, Subscription } from 'rxjs';
import { Transaction } from '../../interfaces/withdrawal.interfaces';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { WithdrawalService } from '../../services/withdrawal.service';
import { CancelWithdrawComponent } from '../cancel-withdraw/cancel-withdraw.component';
import { FILTER_TYPES } from '../../enums/withdrawal.enums';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';
import { Dayjs } from 'dayjs/esm';

@Component({
  selector: 'app-transactions',
  standalone: true,
  imports: [
    CustomCheckboxDropdownComponent,
    NgForOf,
    NgIf,
    NgxPaginationModule,
    TransactionsTableComponent,
    CancelWithdrawComponent,
    NgxDaterangepickerMd,
    FormsModule
  ],
  templateUrl: './transactions.component.html',
  styleUrl: './transactions.component.scss'
})
export class TransactionsComponent implements OnInit {
  private readonly limit = 5;
  withdrawals: Transaction[] = [];

  @AutoUnsubscribe()
  private withdrawalObserverSubs: Subscription | undefined;
  @AutoUnsubscribe()
  private filtersSubs: Subscription | undefined;

  constructor(public withdrawalService: WithdrawalService) {}

  ngOnInit() {
    this.withdrawalService.getUserWithdrawals(1);

    this.withdrawalObserverSubs = this.withdrawalService.withdrawalObserver.subscribe({
      next: (response) => {
        this.withdrawals = sortByCreatedOn(response);
        if (this.limit > 0) {
          this.withdrawals = this.withdrawals.slice(0, this.limit);
        }
      }
    });

    this.filtersSubs = this.withdrawalService.selectedFilters$.pipe(skip(1), debounceTime(1250)).subscribe({
      next: (filters) => {
        console.log('filters', filters);
        this.withdrawalService.getUserWithdrawals(1, filters.categories, filters.status);
      }
    });

    this.withdrawalService.transactionSearch$.pipe(skip(1), debounceTime(1250)).subscribe({
      next: (search) => {
        console.log('transactionSearch$', search);
        this.withdrawalService.getUserWithdrawals(1);
      }
    });
  }

  onCheckboxChange(filterType: FILTER_TYPES, value: string) {
    this.withdrawalService.handleFilterCheck(filterType, value);
  }

  pageChanged(event: any) {
    this.withdrawalService.getUserWithdrawals(event);
    this.withdrawalService.currentPage = event;
  }

  protected readonly FILTER_TYPES = FILTER_TYPES;

  datesUpdated(dateRange: { startDate: Dayjs; endDate: Dayjs }) {
    if (dateRange.startDate && dateRange.endDate) {
      this.withdrawalService.dateRange = {
        end: dateRange.endDate.format('YYYY-MM-DD'),
        start: dateRange.startDate.format('YYYY-MM-DD')
      };
      this.withdrawalService.getUserWithdrawals(1);
    }
  }

  onSearchInput(searchStr: string) {
    this.withdrawalService.transactionSearch$.next(searchStr);
  }
}
