import { Component, OnInit } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { Button } from '../../interfaces/button.interfaces';
import { BalanceService } from '../../services/balance.service';
import { WithdrawalService } from '../../services/withdrawal.service';
import { ModalService } from '../../services/modal.service';
import { MODALS } from '../../enums/modal.enums';
import { WithdrawModalComponent } from '../modals/withdraw-modal/withdraw-modal.component';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { BUTTON_SIZE } from '../../enums/button.enums';
import { DownloadModalComponent } from '../modals/download-modal/download-modal.component';

@Component({
  selector: 'app-balance-section',
  standalone: true,
  imports: [AsyncPipe, DecimalPipe, ButtonComponent],
  templateUrl: './balance-section.component.html',
  styleUrl: './balance-section.component.scss'
})
export class BalanceSectionComponent implements OnInit {
  private isWithdrawDisabled = false;
  isWithdrawDisabled$: Observable<boolean> = of(false);

  readonly sendBtn: Button = {
    icon: 'icon-arrow-right-up',
    name: 'Send',
    size: BUTTON_SIZE.SMALL
  };

  constructor(
    public balanceService: BalanceService,
    public withdrawalService: WithdrawalService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.isWithdrawDisabled$ = this.withdrawalService.getWithdrawalOptionsRequest().pipe(
      switchMap((options) => {
        this.isWithdrawDisabled = !!(
          !options.success &&
          options.error &&
          options.error.description === 'Transfer is temporarily disabled.'
        );
        return of(this.isWithdrawDisabled);
      })
    );
  }

  get withdrawalBtnTitle(): string {
    return this.withdrawalService.pendingWithdrawalData.isPending
      ? 'Please finish the PENDING transfer before creating a new one.'
      : this.isWithdrawDisabled
      ? 'Transfer is temporarily disabled'
      : '';
  }

  openWithdrawModal() {
    this.modalService.create(MODALS.WITHDRAW, WithdrawModalComponent).open();
  }

  openDownloadModal() {
    this.modalService.create(MODALS.DOWNLOAD, DownloadModalComponent).open();
  }
}
