import { Component } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { Button } from '../../../interfaces/button.interfaces';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'app-download-modal',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './download-modal.component.html',
  styleUrl: './download-modal.component.scss'
})
export class DownloadModalComponent {
  readonly downloadBtn: Button = {
    name: 'Download App for Windows',
    classMod: 'download'
  };

  constructor(private dashboardService: DashboardService) {}

  download() {
    this.dashboardService.downloadGme();
  }
}
