import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from './auth.service';

import { environment } from '../../../environments/environment';
import {
  MakeWithdrawal,
  PendingWithdrawalData,
  Transaction,
  TransactionDataResponse,
  WithdrawalData,
  WithdrawalOptionResponse
} from '../interfaces/withdrawal.interfaces';
import { GaiminResponse } from '../interfaces/http.interfaces';
import { FILTER_TYPES } from '../enums/withdrawal.enums';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {
  userWithdrawalsSub: Subscription | undefined;
  withdrawalIn: BehaviorSubject<Transaction[]> = new BehaviorSubject<Transaction[]>([]);
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalItems: any;

  selectedFilters$ = new BehaviorSubject<{ status: string[]; categories: string[] }>({
    status: [],
    categories: []
  });

  transactionsFilters = {
    [FILTER_TYPES.CATEGORIES]: [
      { id: 'REWARD', name: 'Reward', isChecked: false },
      { id: 'NEP_REWARD', name: 'Nep reward', isChecked: false },
      { id: 'RENDERING_REWARD', name: 'Rendering reward', isChecked: false },
      { id: 'STREAMING_REWARD', name: 'Streaming reward', isChecked: false },
      { id: 'TRANSFER_IN', name: 'Transfer in', isChecked: false },
      { id: 'TRANSFER_OUT', name: 'Transfer out', isChecked: false },
      { id: 'BONUS', name: 'Bonus', isChecked: false },
      { id: 'WITHDRAW', name: 'Withdraw', isChecked: false },
      { id: 'PURCHASE', name: 'Purchase', isChecked: false },
      { id: 'AI_REWARD', name: 'AI Reward', isChecked: false }
    ],

    [FILTER_TYPES.STATUS]: [
      { id: 'COMPLETED', name: 'Completed', isChecked: false },
      { id: 'REFUND_COMPLETED', name: 'Refund Completed', isChecked: false },
      { id: 'VERIFIED', name: 'Verified', isChecked: false },
      { id: 'PENDING_EMAIL_VERIFICATION', name: 'Pending Email Verification', isChecked: false },
      { id: 'PENDING', name: 'Pending', isChecked: false },
      { id: 'REFUND_PENDING', name: 'Refund Pending', isChecked: false },
      { id: 'EXPIRED', name: 'Expired', isChecked: false },
      { id: 'REFUND_FAILED', name: 'Refund Failed', isChecked: false },
      { id: 'FAILED', name: 'Failed', isChecked: false },
      { id: 'CANCELLED', name: 'Cancelled', isChecked: false },
      { id: 'CLOSED', name: 'Closed', isChecked: false }
    ]
  };

  dateRange = {
    start: this.datePipe.transform(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd') || '',
    end: this.datePipe.transform(new Date(), 'yyyy-MM-dd') || ''
  };

  transactionSearch$ = new BehaviorSubject<string>('');

  get withdrawalObserver(): Observable<Transaction[]> {
    return this.withdrawalIn.asObservable();
  }

  pendingWithdrawalData = {
    isPending: false,
    status: '',
    amount: 0,
    currency: ''
  };

  constructor(private http: HttpClient, private authService: AuthService, private datePipe: DatePipe) {
    const now = new Date();
    const oneMonthAgo = new Date();

    // Subtract one month while keeping the day consistent
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    this.dateRange = {
      start: this.datePipe.transform(oneMonthAgo, 'yyyy-MM-dd') || '',
      end: this.datePipe.transform(now, 'yyyy-MM-dd') || ''
    };

    this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.resetFilters();
        }
      }
    });
  }

  checkUserPendingWithdrawal() {
    this.getUserPendingWithdrawalRequest().subscribe({
      next: (userPendingWithdrawalResponse) => {
        if (
          userPendingWithdrawalResponse.data &&
          userPendingWithdrawalResponse.success &&
          userPendingWithdrawalResponse.data.activeWithdrawal &&
          userPendingWithdrawalResponse.data.withdrawal
        ) {
          this.pendingWithdrawalData.isPending = true;
          this.pendingWithdrawalData.status = userPendingWithdrawalResponse.data.withdrawal.status;
          this.pendingWithdrawalData.amount = userPendingWithdrawalResponse.data.withdrawal.amount;
          this.pendingWithdrawalData.currency = userPendingWithdrawalResponse.data.withdrawal.currencyCode;
        } else {
          this.pendingWithdrawalData.isPending = false;
          this.pendingWithdrawalData.status = '';
          this.pendingWithdrawalData.amount = 0;
          this.pendingWithdrawalData.currency = '';
        }
      }
    });
  }

  private getUserPendingWithdrawalRequest(): Observable<GaiminResponse<PendingWithdrawalData>> {
    return this.http.get<GaiminResponse<PendingWithdrawalData>>(`${environment.gaiminApi + `/withdrawal/pending`}`, {
      headers: this.authService.authHeaders()
    });
  }

  getUserWithdrawals(
    page: number,
    transactionTypes = this.selectedFilters$.value.categories,
    statuses = this.selectedFilters$.value.status,
    dateRange = this.dateRange,
    search = this.transactionSearch$.value
  ) {
    const params = {
      page,
      fromDate: dateRange.end,
      toDate: dateRange.start,
      size: this.itemsPerPage,
      types: transactionTypes,
      status: statuses
    };

    if (search.length > 0) {
      Object.assign(params, { transactionHash: search });
    }

    this.userWithdrawalsSub = this.http
      .get<GaiminResponse<TransactionDataResponse>>(environment.gaiminApi + `/users/me/transactions`, {
        headers: this.authService.authHeaders(),
        params
      })
      .subscribe({
        next: (response) => {
          if (response.success && response.data) {
            this.withdrawalIn.next(response.data.transactions);
            this.checkUserPendingWithdrawal();
            this.totalItems = response.data.pagination.totalResults;
            this.currentPage = page;
          }
        }
      });
  }

  handleFilterCheck(filterType: FILTER_TYPES, filterId: string) {
    this.handleChangeIsCheckedById(filterType, filterId);

    const selectedFilters = this.transactionsFilters[filterType].filter((filter) => filter.isChecked);
    const selectedFiltersIds = selectedFilters.map((selectedFilter) => selectedFilter.id);

    const selected$ = this.selectedFilters$.value;
    selected$[filterType] = [...selectedFiltersIds];
    this.selectedFilters$.next(selected$);
  }

  private resetFilters() {
    for (const key in this.transactionsFilters) {
      this.transactionsFilters[key as FILTER_TYPES].map((filter) => {
        filter.isChecked = false;
        return filter;
      });
    }
  }

  private handleChangeIsCheckedById(filterType: FILTER_TYPES, filterId: string) {
    this.transactionsFilters[filterType] = this.transactionsFilters[filterType].map((filter) => {
      if (filter.id === filterId) {
        filter.isChecked = !filter.isChecked;
      }

      return filter;
    });
  }

  checkMinimumWithdrawalAmount(currency: string) {
    return this.http.get<GaiminResponse<number>>(
      environment.gaiminApi + '/withdrawal/minimum-amount?currency=' + currency,
      {
        headers: this.authService.authHeaders()
      }
    );
  }

  getWithdrawalOptionsRequest() {
    return this.http.get<GaiminResponse<WithdrawalOptionResponse>>(environment.gaiminApi + '/withdrawals/options', {
      headers: this.authService.authHeaders()
    });
  }

  makeWithdrawal(data: MakeWithdrawal): Observable<GaiminResponse<WithdrawalData[]>> {
    return this.http.post<GaiminResponse<WithdrawalData[]>>(environment.gaiminApi + '/users/me/withdrawal', data, {
      headers: this.authService.authHeaders()
    });
  }

  cancelWithdrawal(): Observable<GaiminResponse<WithdrawalData[]>> {
    return this.http.post<GaiminResponse<WithdrawalData[]>>(
      environment.gaiminApi + '/users/me/withdrawal/cancel-all',
      {},
      {
        headers: this.authService.authHeaders()
      }
    );
  }
}
