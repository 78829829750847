import { Component, Input } from '@angular/core';
import { WithdrawalService } from '../../services/withdrawal.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe, NgForOf, NgIf, NgStyle } from '@angular/common';
import { Transaction } from '../../interfaces/withdrawal.interfaces';
import { TRANSACTION_STATUS } from '../../enums/withdrawal.enums';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';
import { NormalizeWalletPipe } from '../../pipes/normilize-wallet.pipe';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-transactions-table',
  templateUrl: './transactions-table.component.html',
  standalone: true,
  imports: [NgxPaginationModule, DatePipe, NgStyle, NgIf, NgForOf, NormalizeWalletPipe],
  styleUrls: ['./transactions-table.component.scss']
})
export class TransactionsTableComponent {
  constructor(
    private clipboardService: ClipboardService,
    private toastrService: ToastrService,
    public withdrawalService: WithdrawalService
  ) {}

  @Input() withdrawals: Transaction[] = [];

  roundUpToX(num: number, decimals: number): number {
    const decimal: number = Math.pow(10, decimals);
    return Math.ceil(num * decimal) / decimal;
  }

  normalizeStatus(status: string) {
    status = status.replace(/_/g, ' ');
    return `${status[0].toUpperCase()}${status.slice(1).toLowerCase()}`;
  }

  onCopy(walletAddress: string) {
    this.clipboardService.copy(walletAddress);
    this.toastrService.success(`The address "${walletAddress}" copied.`);
  }

  getStatusColor(status: TRANSACTION_STATUS) {
    switch (status) {
      case TRANSACTION_STATUS.COMPLETED:
      case TRANSACTION_STATUS.VERIFIED:
      case TRANSACTION_STATUS.REFUND_COMPLETED:
        return '#20C083';
      case TRANSACTION_STATUS.PENDING:
      case TRANSACTION_STATUS.PENDING_EMAIL_VERIFICATION:
      case TRANSACTION_STATUS.REFUND_PENDING:
        return '#20C083';
      case TRANSACTION_STATUS.FAILED:
      case TRANSACTION_STATUS.CANCELLED:
      case TRANSACTION_STATUS.REFUND_FAILED:
      case TRANSACTION_STATUS.EXPIRED:
      case TRANSACTION_STATUS.CLOSED:
        return '#f85c5c';
      default:
        console.log('Wrong status', status);
        return '#20C083';
    }
  }

  openBscScan(transferTxHash: string) {
    window.open(`${environment.bscScan}/tx/${transferTxHash}`, '_blank');
  }
}
