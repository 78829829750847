import { Component, OnInit } from '@angular/core';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { WithdrawalService } from '../../services/withdrawal.service';
import { BalanceService } from '../../services/balance.service';
import { ButtonComponent } from '../button/button.component';
import { Button } from '../../interfaces/button.interfaces';
import { WithdrawModalComponent } from '../modals/withdraw-modal/withdraw-modal.component';
import { ModalService } from '../../services/modal.service';
import { MODALS } from '../../enums/modal.enums';
import { Subscription, tap } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { WithdrawalOptionResponse } from '../../interfaces/withdrawal.interfaces';
import { GaiminResponse } from '../../interfaces/http.interfaces';

@Component({
  selector: 'app-withdrawal-section',
  standalone: true,
  imports: [DecimalPipe, AsyncPipe, ButtonComponent],
  templateUrl: './withdrawal-section.component.html',
  styleUrl: './withdrawal-section.component.scss'
})
export class WithdrawalSectionComponent implements OnInit {
  @AutoUnsubscribe()
  private withdrawOptionsSub: Subscription | undefined;
  isWithdrawDisabled: boolean = true;

  readonly button: Button = {
    icon: 'icon-Withdraw',
    name: 'Transfer'
  };

  constructor(
    public balanceService: BalanceService,
    public withdrawalService: WithdrawalService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.withdrawOptionsSub = this.withdrawalService
      .getWithdrawalOptionsRequest()
      .pipe(
        tap((res: GaiminResponse<WithdrawalOptionResponse>) => {
          this.isWithdrawDisabled = !!(
            !res.success &&
            res.error &&
            res.error.description === 'Transfer is temporarily disabled.'
          );
        })
      )
      .subscribe();
  }

  get isPending(): boolean {
    return this.withdrawalService.pendingWithdrawalData.isPending;
  }

  onClick() {
    this.modalService.create(MODALS.WITHDRAW, WithdrawModalComponent).open();
  }
}
