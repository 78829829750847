import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from '../../../shared/components/button/button.component';

@Component({
  selector: 'app-cta-section',
  standalone: true,
  templateUrl: './cta-section.component.html',
  styleUrl: './cta-section.component.scss',
  imports: [ButtonComponent],
  host: {
    '[class.section]': 'true'
  }
})
export class CtaSectionComponent {
  constructor(
    private router: Router
  ) {}

  async navigateToDashboard() {
    await this.router.navigateByUrl('/dashboard');
  }
}
