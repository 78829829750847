import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  standalone: true,
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input() color: string = '#000000';

  constructor() {}
}
