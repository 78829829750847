export enum FILTER_TYPES {
  STATUS = 'status',
  CATEGORIES = 'categories'
}

export enum TRANSACTION_STATUS {
  COMPLETED = 'COMPLETED',
  REFUND_COMPLETED = 'REFUND_COMPLETED',
  VERIFIED = 'VERIFIED',

  PENDING_EMAIL_VERIFICATION = 'PENDING_EMAIL_VERIFICATION',
  PENDING = 'PENDING',
  REFUND_PENDING = 'REFUND_PENDING',

  EXPIRED = 'EXPIRED',
  REFUND_FAILED = 'REFUND_FAILED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED'
}

export enum CHAIN {
  POLYGON = 'POLYGON',
  MUMBAI = 'MUMBAI',
  BNB_CHAIN = 'BNB_CHAIN',
  BNB_CHAIN_TESTNET = 'BNB_CHAIN_TESTNET',
  HEDERA = 'HEDERA',
  NA = 'NA',
  SOLANA = 'SOLANA',
  SOLANA_TESTNET = 'SOLANA_TESTNET',
  SOLANA_DEVNET = 'SOLANA_DEVNET'
}

export enum WITHDRAW_CURRENCY {
  USDC_BNB = 'USDC_BNB',
  GMRX = 'GMRX'
}
