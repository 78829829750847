import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'size',
  standalone: true
})
export class SizePipe implements PipeTransform {
  transform(value: number): string {
    if (!value && value !== 0) return '';

    const oneGB = 1024;

    if (value >= oneGB) {
      return (value / oneGB).toFixed(2) + ' GB';
    } else {
      return value.toFixed(2) + ' MB';
    }
  }
}
