<div *ngIf="!isLoading" class="metrics">
  <h3 class="metrics__title">Monetization</h3>

  <div class="metrics__chart">

    <div class="metrics__chart_filters">
      <button
        (click)="setFilter(MONETIZATION_FILTERS.W1)"
        [class.active]="selectedFilter === MONETIZATION_FILTERS.W1">
        1W
      </button>
      <button
        (click)="setFilter(MONETIZATION_FILTERS.M1)"
        [class.active]="selectedFilter === MONETIZATION_FILTERS.M1">
        1M
      </button>
      <button
        (click)="setFilter(MONETIZATION_FILTERS.M3)"
        [class.active]="selectedFilter === MONETIZATION_FILTERS.M3">
        3M
      </button>
      <button
        (click)="setFilter(MONETIZATION_FILTERS.M6)"
        [class.active]="selectedFilter === MONETIZATION_FILTERS.M6">
        6M
      </button>
    </div>


    <apx-chart
      #chart
      [series]="chartOptions.series!"
      [chart]="chartOptions.chart!"
      [xaxis]="chartOptions.xaxis!"
      [yaxis]="chartOptions.yaxis!"
      [dataLabels]="chartOptions.dataLabels!"
      [grid]="chartOptions.grid!"
      [stroke]="chartOptions.stroke!"
      [tooltip]="chartOptions.tooltip!"
      [markers]="chartOptions.markers!"
      [display]="false"
    ></apx-chart>
  </div>

</div>

