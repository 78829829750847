<div class="download-modal">
  <img src="./assets/images/download-image.png" alt="">

  <app-button [buttonData]="downloadBtn" (click)="download()" />

  <p class="download-modal__soon">Linux Available Soon</p>

  <div class="download-modal__requirements">
    <h3>Minimum system requirements</h3>

    <div class="download-modal__requirements_info">
      <div>
        <p>Operating System:</p>
        <p>Processor:</p>
        <p>Memory (RAM):</p>
        <p>Graphics Card:</p>
        <p>Storage:</p>
        <p>Internet Connection:</p>
      </div>

      <div>
        <p>Windows 10 or Windows 11 (64-bit)</p>
        <p>Intel Core i3 or AMD equivalent</p>
        <p>1GB or higher</p>
        <p>DirectX 11 compatible graphics card with at least 4GB VRAM</p>
        <p>Stable internet connection for online features and updates</p>
      </div>

    </div>
  </div>

</div>
