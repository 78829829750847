import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ModalService } from './shared/services/modal.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  isLanding$: Observable<boolean> = of(false);

  constructor(private modalService: ModalService, private vcr: ViewContainerRef, public router: Router) {
    /** Providing App Component refference for ModalSerice */
    modalService.modalParentComponentRefference = this.vcr;

    this.isLanding$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url === '/')
    );
  }

  ngOnInit(): void {}
}
