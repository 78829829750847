import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { DeviceData, MonetizationMetricsResponse } from '../interfaces/dashboard.interfaces';
import { environment } from '../../../environments/environment';
import { GaiminResponse } from '../interfaces/http.interfaces';
import { DeviceInfo } from '../interfaces/devices.interfaces';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  devices$: BehaviorSubject<DeviceData[]> = new BehaviorSubject<DeviceData[]>([]);

  constructor(private http: HttpClient, private authService: AuthService) {}

  downloadGMERequest(): Observable<GaiminResponse<string>> {
    return this.http.get<GaiminResponse<string>>(`${environment.gaiminApi}/gme/download/url`, {
      headers: this.authService.authHeaders()
    });
  }

  getDeviceInfo(): Observable<GaiminResponse<DeviceInfo>> {
    return this.http.get<GaiminResponse<DeviceInfo>>(`${environment.gaiminApi}/devices/me/system-info`, {
      headers: this.authService.authHeaders()
    });
  }

  private handleDeviceVisibleRequest(deviceId: number, hide: boolean): Observable<GaiminResponse<DeviceData>> {
    return this.http.patch<GaiminResponse<DeviceData>>(
      `${environment.gaiminApi}/users/me/device-visibility`,
      {},
      {
        params: {
          deviceId,
          hidden: hide
        },
        headers: this.authService.authHeaders()
      }
    );
  }

  handleDeviceVisible(device: DeviceData) {
    const deviceId = device.device.id;
    const changeVisibilityTo = !device.device.hidden;
    this.handleDeviceVisibleRequest(deviceId, changeVisibilityTo).subscribe({
      next: (deviceData: GaiminResponse<DeviceData>) => {
        this.updateDeviceData(deviceData.data!);
      }
    });
  }

  private updateDeviceData(deviceData: DeviceData) {
    const devices = this.devices$.getValue();
    const index = devices.findIndex((device: DeviceData) => device.device.id === deviceData.device.id);
    if (index !== -1) {
      devices[index] = deviceData;
      this.devices$.next(devices);
    }
  }

  getDeviceAll() {
    this.getUserDevicesRequest()
      .pipe(
        tap((response) => {
          this.devices$.next(response.data!);
        })
      )
      .subscribe();
  }

  reassignDeviceRequest(toEmail: string, deviceId: number): Observable<GaiminResponse<any>> {
    return this.http.post<GaiminResponse<any>>(
      `${environment.gaiminApi}/users/me/init-reassign-device`,
      {
        toEmail,
        deviceId
      },
      {
        headers: this.authService.authHeaders()
      }
    );
  }

  getUserDevicesRequest(details = true): Observable<GaiminResponse<DeviceData[]>> {
    return this.http.get<GaiminResponse<DeviceData[]>>(environment.gaiminApi + '/users/me/devices', {
      headers: this.authService.authHeaders(),
      params: {
        withBalances: details,
        withSystemInfo: details
      }
    });
  }

  getMonetizationMetricsRequest(): Observable<GaiminResponse<MonetizationMetricsResponse>> {
    return this.http.get<GaiminResponse<MonetizationMetricsResponse>>(
      `${environment.gaiminApi}/statistics/monetization`,
      {
        headers: this.authService.authHeaders()
      }
    );
  }
}
