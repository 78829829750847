export function normalizeWalletAddress(walletAddress: string): string {
  return `${walletAddress.slice(0, 6)}....${walletAddress.slice(-4)}`;
}

export function sortByCreatedOn(array: any[]) {
  return array.sort((a: any, b: any) => new Date(b?.createdOn).getTime() - new Date(a?.createdOn).getTime());
}

export function sortByUSDC<T extends { balance?: { paid?: { USDC_POLYGON?: number } } }>(array: T[]) {
  return array.sort((a: T, b: T) => b!.balance!.paid!.USDC_POLYGON! - a!.balance!.paid!.USDC_POLYGON!);
}

export function backClicked() {
  window.history.back();
}
