import { Component, OnInit } from '@angular/core';
import { MetricsMonetizationComponent } from '../../shared/components/metrics-monetization/metrics-monetization.component';
import { BalanceSectionComponent } from '../../shared/components/balance-section/balance-section.component';
import { TransactionsComponent } from '../../shared/components/transactions/transactions.component';
import { DevicesSectionComponent } from '../../shared/components/devices-section/devices-section.component';

@Component({
  selector: 'app-dashboard-page',
  standalone: true,
  imports: [BalanceSectionComponent, MetricsMonetizationComponent, TransactionsComponent, DevicesSectionComponent],
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss'
})
export class DashboardPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
