<div class="balance-section">

  <div class="balance-section__balance">
    <p class="balance-section__balance_title">My Balance <span class="icon-wallet-balance"></span></p>

    <div class="balance-section__balance_gmrx">
      {{ (balanceService.userPaidBalance$ | async)?.GMRX | number : '1.5-5':'en-US' }} <span>GMRX</span>
    </div>
    <div class="balance-section__balance_usd">
      ≈ $ {{ (balanceService.userPaidBalance$ | async)?.USDC_POLYGON | number : '1.5-5':'en-US' }}
    </div>

    <app-button [title]="withdrawalBtnTitle"
                [buttonData]="sendBtn"
                (click)="openWithdrawModal()" />
  </div>

  <div class="balance-section__download">
    <img src="assets/images/server.png" alt="">
    <h4>GAIMIN Monetization</h4>
    <span class="balance-section__download_action" (click)="openDownloadModal()">Download</span>
  </div>

</div>
