import { Component, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { LogoComponent } from '../../shared/components/logo/logo.component';
import { AuthService } from '../../shared/services/auth.service';
import { UserService } from '../../shared/services/user.service';
import { map, Observable } from 'rxjs';
import { UserInfo } from '../../shared/interfaces/user.interfaces';
import { AvatarComponent } from '../../shared/components/avatar/avatar.component';
import { DropdownMenuComponent } from '../../shared/components/dropdown-menu/dropdown-menu.component';
import { Router } from '@angular/router';
import { CtaSectionComponent } from './cta-section/cta-section.component';
import { FeaturesSectionComponent } from './features-section/features-section.component';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [
    ButtonComponent,
    LogoComponent,
    AsyncPipe,
    NgIf,
    AvatarComponent,
    DropdownMenuComponent,
    CtaSectionComponent,
    FeaturesSectionComponent
  ],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent implements OnInit {
  dropdownMenuItems = [
    { name: 'Dashboard', icon: '/assets/icons/dashboard.svg', action: () => this.router.navigateByUrl('/dashboard') },
    { name: 'Log out', action: () => this.logout() }
  ];

  ngOnInit() {}

  constructor(private router: Router, public authService: AuthService, public userService: UserService) {}

  get profile(): Observable<Partial<UserInfo>> {
    return this.userService.profile$;
  }

  login() {
    this.authService.loginWithOkta('/dashboard');
  }

  async logout() {
    await this.authService.logout();
  }
}
