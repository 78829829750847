import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from './auth.service';

import { environment } from '../../../environments/environment';
import {
  MakeWithdrawal,
  PendingWithdrawalData,
  Transaction,
  TransactionDataResponse,
  WithdrawalData,
  WithdrawalOptionResponse
} from '../interfaces/withdrawal.interfaces';
import { GaiminResponse } from '../interfaces/http.interfaces';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {
  userWithdrawalsSub: Subscription | undefined;
  withdrawalIn: BehaviorSubject<Transaction[]> = new BehaviorSubject<Transaction[]>([]);
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: any;

  selectedFilters$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  filters = [
    {
      id: 'REWARD',
      name: 'Reward',
      isChecked: false
    },
    {
      id: 'NEP_REWARD',
      name: 'Nep reward',
      isChecked: false
    },
    {
      id: 'RENDERING_REWARD',
      name: 'Rendering reward',
      isChecked: false
    },
    {
      id: 'STREAMING_REWARD',
      name: 'Streaming reward',
      isChecked: false
    },
    {
      id: 'TRANSFER_IN',
      name: 'Transfer in',
      isChecked: false
    },
    {
      id: 'TRANSFER_OUT',
      name: 'Transfer out',
      isChecked: false
    },
    {
      id: 'BONUS',
      name: 'Bonus',
      isChecked: false
    },
    {
      id: 'WITHDRAW',
      name: 'Withdraw',
      isChecked: false
    },
    {
      id: 'PURCHASE',
      name: 'Purchase',
      isChecked: false
    },
    {
      id: 'AI_REWARD',
      name: 'AI Reward',
      isChecked: false
    }
  ];

  get withdrawalObserver(): Observable<Transaction[]> {
    return this.withdrawalIn.asObservable();
  }

  pendingWithdrawalData = {
    isPending: false,
    status: '',
    amount: 0,
    currency: ''
  };

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.resetFilters();
        }
      }
    });
  }

  checkUserPendingWithdrawal() {
    this.getUserPendingWithdrawalRequest().subscribe({
      next: (userPendingWithdrawalResponse) => {
        if (
          userPendingWithdrawalResponse.data &&
          userPendingWithdrawalResponse.success &&
          userPendingWithdrawalResponse.data.activeWithdrawal &&
          userPendingWithdrawalResponse.data.withdrawal
        ) {
          this.pendingWithdrawalData.isPending = true;
          this.pendingWithdrawalData.status = userPendingWithdrawalResponse.data.withdrawal.status;
          this.pendingWithdrawalData.amount = userPendingWithdrawalResponse.data.withdrawal.amount;
          this.pendingWithdrawalData.currency = userPendingWithdrawalResponse.data.withdrawal.currencyCode;
        } else {
          this.pendingWithdrawalData.isPending = false;
          this.pendingWithdrawalData.status = '';
          this.pendingWithdrawalData.amount = 0;
          this.pendingWithdrawalData.currency = '';
        }
      }
    });
  }

  private getUserPendingWithdrawalRequest(): Observable<GaiminResponse<PendingWithdrawalData>> {
    return this.http.get<GaiminResponse<PendingWithdrawalData>>(`${environment.gaiminApi + `/withdrawal/pending`}`, {
      headers: this.authService.authHeaders()
    });
  }

  getUserWithdrawals(page: number, transactionTypes: string[] = this.selectedFilters$.value) {
    this.userWithdrawalsSub = this.http
      .get<GaiminResponse<TransactionDataResponse>>(environment.gaiminApi + `/users/me/transactions`, {
        headers: this.authService.authHeaders(),
        params: {
          page,
          size: this.itemsPerPage,
          types: transactionTypes
        }
      })
      .subscribe({
        next: (response) => {
          if (response.success && response.data) {
            this.withdrawalIn.next(response.data.transactions);
            this.checkUserPendingWithdrawal();
            this.totalItems = response.data.pagination.totalResults;
            this.currentPage = page;
          }
        }
      });
  }

  handleFilterCheck(filterId: string) {
    this.handleChangeIsCheckedById(filterId);

    const selectedFilters = this.filters.filter((filter) => filter.isChecked);
    const selectedFiltersIds = selectedFilters.map((selectedFilter) => selectedFilter.id);
    this.selectedFilters$.next(selectedFiltersIds);
  }

  private resetFilters() {
    this.filters = this.filters.map((filter) => {
      filter.isChecked = false;
      return filter;
    });
  }

  private handleChangeIsCheckedById(filterId: string) {
    this.filters = this.filters.map((filter) => {
      if (filter.id === filterId) {
        filter.isChecked = !filter.isChecked;
      }

      return filter;
    });
  }

  checkMinimumWithdrawalAmount(currency: string) {
    return this.http.get<GaiminResponse<number>>(
      environment.gaiminApi + '/withdrawal/minimum-amount?currency=' + currency,
      {
        headers: this.authService.authHeaders()
      }
    );
  }

  getWithdrawalOptionsRequest() {
    return this.http.get<GaiminResponse<WithdrawalOptionResponse>>(environment.gaiminApi + '/withdrawals/options', {
      headers: this.authService.authHeaders()
    });
  }

  makeWithdrawal(data: MakeWithdrawal): Observable<GaiminResponse<WithdrawalData[]>> {
    return this.http.post<GaiminResponse<WithdrawalData[]>>(environment.gaiminApi + '/users/me/withdrawal', data, {
      headers: this.authService.authHeaders()
    });
  }

  cancelWithdrawal(): Observable<GaiminResponse<WithdrawalData[]>> {
    return this.http.post<GaiminResponse<WithdrawalData[]>>(environment.gaiminApi + '/users/me/withdrawal/cancel-all',{}, {
      headers: this.authService.authHeaders()
    });
  }
}
