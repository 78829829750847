import { Component } from '@angular/core';
import { AvatarComponent } from '../avatar/avatar.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { UserInfo } from '../../interfaces/user.interfaces';
import { UserService } from '../../services/user.service';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [AvatarComponent, AsyncPipe, DropdownMenuComponent, NgIf],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  readonly dropdownMenuItems = [{ name: 'Log out', action: () => this.authService.logout() }];

  constructor(private userService: UserService, public authService: AuthService) {}

  get profile(): Observable<Partial<UserInfo>> {
    return this.userService.profile$;
  }
}
