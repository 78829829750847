import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OktaAuthModule } from '@okta/okta-angular';
import { NgModule } from '@angular/core';
import OktaAuth from '@okta/okta-auth-js';
import oktaConfig from './app.config';
import { AppRoutingModule } from './app.routes';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from '@amin-karimi/ng2-tooltip-directive';
import { HeaderComponent } from './shared/components/header/header.component';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true,
      timeOut: 5000,
      extendedTimeOut: 5000,
      preventDuplicates: true
    }),
    TooltipModule.forRoot({
      'hide-delay': 0
    }),
    AppRoutingModule,
    NgxSmartModalModule.forRoot(),
    HeaderComponent,
    FormsModule,
    NgxDaterangepickerMd.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, DatePipe]
})
export class AppModule {}
