import { Component, OnInit } from '@angular/core';
import { formatDate, NgForOf, NgIf } from '@angular/common';
import { ApexOptions, NgApexchartsModule } from 'ng-apexcharts';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { DeviceData } from '../../interfaces/devices.interfaces';
import {TooltipModule} from "@amin-karimi/ng2-tooltip-directive";

@Component({
  selector: 'app-metrics-monetization',
  templateUrl: './metrics-monetization.component.html',
  standalone: true,
  imports: [NgForOf, NgApexchartsModule, NgIf, TooltipModule],
  styleUrl: './metrics-monetization.component.scss'
})
export class MetricsMonetizationComponent implements OnInit {
  chartOptions: ApexOptions = {
    series: [
      {
        name: 'GMRX',
        data: []
      }
    ],
    chart: {
      height: 450,
      width: '100%',
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      foreColor: '#fff'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      colors: ['#776bf8'],
      fill: {
        colors: ['#776bf8'],
        opacity: 1
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: []
    },
    yaxis: {
      logarithmic: true,
      labels: {
        formatter(val) {
          return String(Math.trunc((val / 100000) * 100) / 100);
        }
      }
    },
    tooltip: {
      marker: {
        show: false
      },
      theme: 'dark'
    },
    markers: {
      colors: ['#776bf8']
    }
  };

  expectedEarnings: number = 0;
  gpu: DeviceData['systemInfo']['gpu'] | [] = [];
  infoText = `
    <div>
      <div>How to increase monetization rewards:</div>
      <div>1. Increase the level of your monetization power</div>
      <div>2. Upgrade the GPU in your computer</div>
      <div>3. Refer your friends for lifetime share of their monetization rewards</div>
    </div>
  `;
  isLoading = true;

  @AutoUnsubscribe()
  deviceSub: Subscription | undefined;
  @AutoUnsubscribe()
  monetizationSub: Subscription | undefined;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.deviceSub = this.dashboardService.devices$.subscribe((value: any) => {
      this.gpu = value[0]?.systemInfo?.gpu || [];
    });

    this.monetizationSub = this.dashboardService.getMonetizationMetricsRequest().subscribe((response) => {
      const series = response.data?.rewards.map((reward) => reward.value * 100000);
      const value = response.data?.rewards.map((reward) => reward.date);
      this.createChart(series || [], value || []);

      this.expectedEarnings = response.data?.average || 0;

      this.isLoading = false;
    });
  }

  createChart(series: number[], categories: string[]) {
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: 'GMRX',
          data: series
        }
      ],
      xaxis: {
        categories: categories,
        labels: {
          formatter(value) {
            return value ? formatDate(value, 'dd.MM', 'en-US') : '';
          },
          rotateAlways: true,
          rotate: 90,
          offsetY: 36
        },
        tooltip: {
          enabled: false
        }
      }
    };
  }
}
