import { Component, OnInit } from '@angular/core';
import { WithdrawalSectionComponent } from '../../shared/components/withdrawal-section/withdrawal-section.component';
import { AuthService } from '../../shared/services/auth.service';
import { MetricsMonetizationComponent } from '../../shared/components/metrics-monetization/metrics-monetization.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { DashboardService } from '../../shared/services/dashboard.service';
import { CustomCheckboxDropdownComponent } from '../../shared/components/custom-checkbox-dropdown/custom-checkbox-dropdown.component';
import { CancelWithdrawComponent } from '../../shared/components/cancel-withdraw/cancel-withdraw.component';
import { TransactionsTableComponent } from '../../shared/components/transactions-table/transactions-table.component';
import { WithdrawalService } from '../../shared/services/withdrawal.service';
import { debounceTime, skip, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { DevicesTableComponent } from '../../shared/components/devices-table/devices-table.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { Button } from '../../shared/interfaces/button.interfaces';
import { BUTTON_SIZE } from '../../shared/enums/button.enums';
import { Router } from '@angular/router';
import { Transaction } from '../../shared/interfaces/withdrawal.interfaces';
import { DeviceData } from '../../shared/interfaces/dashboard.interfaces';
import { sortByCreatedOn, sortByUSDC } from '../../shared/utils/shared.utils';

@Component({
  selector: 'app-dashboard-page',
  standalone: true,
  imports: [
    WithdrawalSectionComponent,
    NgIf,
    AsyncPipe,
    CustomCheckboxDropdownComponent,
    CancelWithdrawComponent,
    TransactionsTableComponent,
    MetricsMonetizationComponent,
    DevicesTableComponent,
    ButtonComponent
  ],
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss'
})
export class DashboardPageComponent implements OnInit {
  transactionsCount = 0;
  limit = 5;
  withdrawals: Transaction[] = [];
  @AutoUnsubscribe()
  private withdrawalSubs: Subscription | undefined;
  @AutoUnsubscribe()
  private filtersSubs: Subscription | undefined;
  @AutoUnsubscribe()
  private devicesSubs: Subscription | undefined;
  devicesCount = 0;
  isSeeMoreVisible: boolean = false;
  devices: DeviceData[] = [];

  readonly loginBtn: Button = {
    name: 'Login',
    size: BUTTON_SIZE.EXTRA_SMALL
  };

  readonly logoutBtn: Button = {
    name: 'Logout',
    size: BUTTON_SIZE.EXTRA_SMALL
  };
  readonly downloadBtn: Button = {
    name: 'Download',
    size: BUTTON_SIZE.EXTRA_SMALL
  };

  constructor(
    public authService: AuthService,
    public withdrawalService: WithdrawalService,
    private dashboardService: DashboardService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dashboardService.getDeviceAll();
    this.withdrawalService.getUserWithdrawals(1);
    this.withdrawalSubs = this.withdrawalService.withdrawalObserver.subscribe({
      next: (response) => {
        this.withdrawals = sortByCreatedOn(response);
        this.transactionsCount = this.withdrawals.length;
        if (this.limit > 0) {
          this.withdrawals = this.withdrawals.slice(0, this.limit);
        }
      }
    });

    this.devicesSubs = this.dashboardService.devices$.subscribe({
      next: (response) => {
        this.devices = sortByUSDC(response);
        this.devicesCount = this.devices.length;
        if (this.limit > 0) {
          const isLoggedIn: boolean = this.authService.isLoggedIn$.value;
          const filteredDevices = isLoggedIn ? this.devices.filter((device) => !device.device.hidden) : this.devices;

          this.isSeeMoreVisible = this.devices.length > filteredDevices.length;
          this.devices = filteredDevices.slice(0, this.limit);
        }
      }
    });

    this.filtersSubs = this.withdrawalService.selectedFilters$.pipe(skip(1), debounceTime(1250)).subscribe({
      next: (filters) => {
        this.withdrawalService.getUserWithdrawals(1, filters);
      }
    });
  }

  login() {
    this.authService.loginWithOkta();
  }

  download() {
    this.dashboardService.downloadGMERequest().subscribe({
      next:(response)=>{
        if(response.success && response.data) {
          const url = response.data;
          const link = document.createElement('a');
          link.href = url;
          link.click();
        }
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  onCheckboxChange(value: string) {
    this.withdrawalService.handleFilterCheck(value);
  }

  openTransactionsPage() {
    this.router.navigateByUrl('/dashboard/transactions');
  }

  openDevicesPage() {
    this.router.navigateByUrl('/dashboard/devices');
  }
}
