import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { map, Observable, of, tap } from 'rxjs';
import { DeviceData } from '../../interfaces/dashboard.interfaces';
import { AsyncPipe, DatePipe, DecimalPipe, JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { SizePipe } from '../../pipes/size.pipe';
import { ButtonComponent } from '../button/button.component';
import { Button } from '../../interfaces/button.interfaces';
import { BUTTON_SIZE } from '../../enums/button.enums';
import { MONETIZATION_STATUSES } from '../../enums/monetization.enums';

@Component({
  selector: 'app-devices-section',
  standalone: true,
  imports: [AsyncPipe, JsonPipe, NgForOf, NgClass, SizePipe, ButtonComponent, DatePipe, NgIf, DecimalPipe],
  templateUrl: './devices-section.component.html',
  styleUrl: './devices-section.component.scss'
})
export class DevicesSectionComponent implements OnInit {
  protected readonly MONETIZATION_STATUSES = MONETIZATION_STATUSES;

  readonly activateBtn: Button = {
    name: 'Activate',
    size: BUTTON_SIZE.EXTRA_SMALL
  };
  readonly deactivateBtn: Button = {
    name: 'Deactivate',
    size: BUTTON_SIZE.EXTRA_SMALL,
    classMod: 'deactivate'
  };

  loading: boolean = true;

  devices$: Observable<DeviceData[]> = of([]);
  isToggleMonetizationLoading = false;

  get skeletonArray(): number[] {
    return new Array(8).fill(0);
  }

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.initUserDevices();
  }

  initUserDevices() {
    this.loading = true;
    this.devices$ = this.dashboardService.getUserDevicesRequest().pipe(
      tap(() => {
        this.loading = false;
      }),
      map((deviceResponse) => {
        return deviceResponse.data ?? [];
      })
    );
  }

  toggleMonetization(deviceId: number, monetizationStatus: MONETIZATION_STATUSES): void {
    this.isToggleMonetizationLoading = true;
    this.dashboardService.toggleMonetizationRequest(deviceId, monetizationStatus).subscribe({
      next: (toggleMonetizationResponse) => {
        console.log('toggleMonetizationResponse', toggleMonetizationResponse);
      },
      complete: () => {
        this.isToggleMonetizationLoading = false;
        this.initUserDevices();
      }
    });
  }
}
