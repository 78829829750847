<section class="cta-section">
  <div class="cta-section__badge">
    <span class="cta-section__badge-text">Over 500k people trust and monetise with GAIMIN</span>
  </div>
  <div class="cta-section__heading">
    <h1 class="cta-section__title">Passive background monetization with GAIMIN</h1>
    <p class="cta-section__subtitle">
      Our application detects available hardware (GPU/CPU/Storage) and starts monetization by contributing processing
      power to gaimin.cloud
    </p>
  </div>
  <div class="cta-section__button-container">
    <app-button
      class="cta-section__button"
      [buttonData]="{ name: 'Monetize Now', classMod: 'monetize' }"
      (click)="navigateToDashboard()"
    />
    <div class="caption">Linux Available Soon</div>
  </div>
</section>
