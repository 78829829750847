import { FILTER_DATE_PERIOD } from '../enums/date.enum';

export function getFilterDatePeriod(filterDatePeriod: FILTER_DATE_PERIOD): { dateFrom: Date; dateTo: Date } | null {
  const now = new Date();

  switch (filterDatePeriod) {
    case FILTER_DATE_PERIOD.W1:
      const w1 = new Date(now);
      w1.setDate(now.getDate() - 7);
      return { dateFrom: w1, dateTo: now };
    case FILTER_DATE_PERIOD.M1:
      const m1 = new Date(now);
      m1.setMonth(now.getMonth() - 1);
      return {
        dateFrom: m1,
        dateTo: now
      };
    case FILTER_DATE_PERIOD.M3:
      const m3 = new Date(now);
      m3.setMonth(now.getMonth() - 3);
      return {
        dateFrom: m3,
        dateTo: now
      };
    case FILTER_DATE_PERIOD.M6:
      const m6 = new Date(now);
      m6.setMonth(now.getMonth() - 6);
      return {
        dateFrom: m6,
        dateTo: now
      };
    default:
      return null;
  }
}
