<header class="header">
  <div class="header__wrapper">
    <img src="assets/images/header-logo.png" alt="">

    <div>
      <app-dropdown-menu
        *ngIf="authService.isLoggedIn$ | async"
        [items]="dropdownMenuItems"
        align="right"
        theme="light"
      >
        <app-avatar [url]="(profile | async)?.avatarUrl" [username]="(profile | async)?.firstName!" />
      </app-dropdown-menu>
    </div>
  </div>

</header>
