<div class="landing">
  <header class="landing__header header">
    <app-logo />
    <div class="header__menu">
      <app-button
        *ngIf="!(authService.isLoggedIn$ | async)"
        [buttonData]="{ name: 'Sign In', classMod: 'signin' }"
        (click)="login()"
      />
      <app-dropdown-menu
        *ngIf="authService.isLoggedIn$ | async"
        [items]="dropdownMenuItems"
      >
        <app-avatar [url]="(profile | async)?.avatarUrl" [username]="(profile | async)?.firstName!"/>
      </app-dropdown-menu>
    </div>
  </header>
  <main class="landing__main">
    <app-cta-section [className]="'section'" />
    <app-features-section [className]="'section'" />
  </main>
  <footer class="landing__footer footer">
    <div class="footer__menu">
      <p class="footer__copyright">GAIMIN AG &copy; 2024. All right reserved.</p>
      <ul class="footer__links">
        <li>
          <a href="#">Terms and Conditions</a>
        </li>
        <li aria-hidden="true" role="none" class="separator">/</li>
        <li>
          <a href="#">Privacy Policy</a>
        </li>
      </ul>
    </div>
  </footer>
</div>
