<section class="features-section">
  <div class="features">
    <div class="features__list">
      <div class="features-item">
        <h2 class="features-item__title">Effortless Earnings</h2>
        <p class="features-item__description">
          Earn passive rewards by using your computer's idle resources, including GPU power, effortlessly.
        </p>
      </div>
      <div class="features-item">
        <h2 class="features-item__title">Optimized Resource Usage</h2>
        <p class="features-item__description">
          GAIMIN optimizes your system's resources, minimizing impact on performance during use.
        </p>
      </div>
      <div class="features-item">
        <h2 class="features-item__title">Rewards in Cryptocurrency</h2>
        <p class="features-item__description">
          Earn GMRX tokens and accumulate digital assets whilst your system runs in the background.
        </p>
      </div>
      <div class="features-item">
        <h2 class="features-item__title">Enhanced Security Measures</h2>
        <p class="features-item__description">
          GAIMIN ensures your device's safety through advanced encryption and security protocols.
        </p>
      </div>
    </div>
  </div>
</section>
