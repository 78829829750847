import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { GaiminResponse } from '../interfaces/http.interfaces';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../interfaces/user.interfaces';
import { DeviceData } from '../interfaces/devices.interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  profile$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>({} as UserInfo);

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.profile$.next({} as UserInfo);
      } else {
        this.getUserInfo().subscribe({
          next: (response) => {
            if (response.success) this.profile$.next(response.data!);
          }
        });
      }
    });
  }

  get profile() {
    return this.profile$.getValue();
  }

  getUserDevicesRequest(details = true): Observable<GaiminResponse<DeviceData[]>> {
    return this.http.get<GaiminResponse<DeviceData[]>>(environment.gaiminApi + '/users/me/devices', {
      headers: this.authService.authHeaders(),
      params: {
        withBalances: details,
        withSystemInfo: details
      }
    });
  }

  getUserInfo(): Observable<GaiminResponse<UserInfo>> {
    return this.http.get<GaiminResponse<UserInfo>>(`${environment.gaiminApi}/users/me`, {
      headers: this.authService.authHeaders()
    });
  }
}
