import { Pipe, PipeTransform } from '@angular/core';
import { normalizeWalletAddress } from '../utils/shared.utils';

@Pipe({
  name: 'normalizeWallet',
  standalone: true
})
export class NormalizeWalletPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return normalizeWalletAddress(value);
  }
}
