<div class="dropdown-menu">
  <div (click)="togglePopup()" class="dropdown-menu__button">
    <ng-content></ng-content>
  </div>
  <div class="dropdown-menu__popup" [ngClass]="['align-' + align, theme]" *ngIf="isOpen">
    <ul>
      <li *ngFor="let item of items" (click)="onMenuItemClick(item.action)">
        <img *ngIf="item.icon" [src]="item.icon" class="icon" alt=""/><span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</div>
