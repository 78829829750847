<div class="transactions">
  <h3 class="transactions__title">My Transactions</h3>

  <div class="transactions-content">

    <div class="transactions-filter">
      <div class="transactions-filter__left">
        <div class="transactions-filter__date">
          <input
            ngxDaterangepickerMd
            [locale]="{ displayFormat: 'MM.DD.YYYY', format: 'YYYY/MM/DD' }"
            startKey="start"
            endKey="end"
            [(ngModel)]="withdrawalService.dateRange"
            (datesUpdated)="datesUpdated($event)"
            type="text" />

          <span class="icon-calendar"></span>
        </div>


        <app-custom-checkbox-dropdown
          [options]="withdrawalService.transactionsFilters.categories"
          [name]="'Categories'"
          (checkboxChange)="onCheckboxChange(FILTER_TYPES.CATEGORIES, $event)"
        ></app-custom-checkbox-dropdown>

        <app-custom-checkbox-dropdown
          [options]="withdrawalService.transactionsFilters.status"
          [name]="'Status'"
          (checkboxChange)="onCheckboxChange(FILTER_TYPES.STATUS, $event)"
        ></app-custom-checkbox-dropdown>
      </div>

      <div class="transactions-filter__search">
        <input [ngModel]="withdrawalService.transactionSearch$.value"
               (ngModelChange)="onSearchInput($event)"
               placeholder="Enter TxID" />
        <span class="icon-search-v2"></span>
      </div>

    </div>

    <app-cancel-withdraw />
    <app-transactions-table [withdrawals]="withdrawals"></app-transactions-table>
    <pagination-template
      class="pagination"
      #p="paginationApi"
      (pageChange)="pageChanged($event)"
      *ngIf="withdrawals?.length > 0">
      <div class="pagination__control" (click)="p.previous()" [class.pagination__control-disabled]="p.isFirstPage()">
        <span class="pagination__arrow-left icon-Arrow-Left"></span>
        <span>Previous</span>
      </div>
      <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
          <span class="pagination__number">{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
          <span class="pagination__number-current">{{ page.label }}</span>
        </div>
      </div>
      <div class="pagination__control" (click)="p.next()" [class.pagination__control-disabled]="p.isLastPage()">
        <span>Next</span>
        <span class="pagination__arrow-right icon-Arrow-Right"></span>
      </div>
    </pagination-template>
  </div>

</div>
