import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  standalone: true,
  imports: [NgIf],
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() url?: string;
  @Input() username!: string;

  get avatarLetter() {
    return this.username ? this.username.charAt(0).toUpperCase() : '?';
  }

  constructor() {}
}
